<!--
 * @Description: 
 * @Author: charles
 * @Date: 2021-07-19 10:03:59
 * @LastEditors: charles
 * @LastEditTime: 2021-08-01 14:08:53
-->
<template>
	<div class="foot">
		<div class="footer_copyright">
			<span>
				<a href="javascript:;" @click="home()" style="margin-right: 20px;">首页</a>
				<a href="javascript:;" @click="about()" style="margin-right: 20px;">关于我们</a>
				<a href="javascript:;" @click="protocol()" style="margin-right: 20px;">服务协议</a>
				<a href="javascript:;" @click="privacy()">隐私政策</a>
			</span>
			
			<br>
			<a href="http://beian.miit.gov.cn" target="_blank">
				<span>粤ICP备2024173954号
				</span>
			</a>
			<span>互联网违法和不良信息举报电话：4001066666-5，涉未成年举报电话：4001066666-9，邮箱：
				<a href="mailto:494717242@qq.com">494717242@qq.com</a>
			</span>
			<br>
			<span>
				中山市小禹哥传媒技术有限公司，通信地址：中山市坦洲镇环洲北路9号锦绣国际花城花月苑56卡之一
			</span>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				
			}
		},
		methods:{
			home() {
				this.$router.push(
					{
						// 要跳转的路由
						path: '/home',
						// 要传递的参数
					}
				)
				
			},
			about() {
				this.$router.push(
					{
						// 要跳转的路由
						path: '/about',
						// 要传递的参数
					}
				)
				
			},
			protocol() {
				let Link = this.$router.resolve({
				     path: '/protocol'
				})
				window.open(Link.href, '_blank')
				// this.$router.push(
				// 	{
				// 		// 要跳转的路由
				// 		path: '/protocol',
				// 		// 要传递的参数
				// 	}
				// )
				
			},
			privacy() {
				let Link = this.$router.resolve({
				     path: '/privacy'
				})
				window.open(Link.href, '_blank')
				// this.$router.push(
				// 	{
				// 		// 要跳转的路由
				// 		path: '/privacy',
				// 		// 要传递的参数
				// 	}
				// )
				
			},
		},
		created() {
		},
	};
</script>
<style lang="scss" scoped>
	.foot {
		background-color: #ccc;
		padding: 10px;
		// width: 85%;
		margin: 0 auto;
		text-align: center;
	}
</style>